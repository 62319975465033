<template>
  <div class="main">
    <div class="wrapper">
      <div class="search">
        <form class="pure-form">
          <i class="fas fa-search"></i>
          <input v-model="searchText" />
        </form>
      </div>
      <div class="pure-menu pure-menu-horizontal">
        <ul class="pure-menu-list">
          <li class="pure-menu-item"><a @click="select('')" href="#" class="pure-menu-link">All</a></li>
          <li class="pure-menu-item"><a @click="select('social')" href="#" class="pure-menu-link">Social Medias</a></li>
          <li class="pure-menu-item"><a @click="select('professional')" href="#" class="pure-menu-link">Coding</a></li>
          <li class="pure-menu-item"><a @click="select('service')" href="#" class="pure-menu-link">Services</a></li>
          <li class="pure-menu-item"><a @click="select('business')" href="#" class="pure-menu-link">Businesses</a></li>
          <li class="pure-menu-item"><a @click="select('random')" href="#" class="pure-menu-link">Random</a></li>
        </ul>
      </div>
    </div>
    <h3>{{description}}</h3>
    <linkList :products="products" />
  </div>
</template>

<script>
import linkList from "../components/linkList.vue"
export default {
  name: 'Home',
  components: {
    linkList
  },
  data() {
    return {
      searchText: '',
      type: ''
    }
  },
  computed: {
    products() {
      if(this.type)
        return this.$root.$data.products.filter(product => product.title.toLowerCase().search(this.searchText.toLowerCase()) >= 0 && product.type.find(type => type === this.type));
      else
        return this.$root.$data.products.filter(product => product.title.toLowerCase().search(this.searchText.toLowerCase()) >= 0);
    },
    description() {
      if(this.type === 'professional')
        return "These links show my Coding ability by either describing jobs, displaying code, or linking to a website I built";
      else if(this.type === 'social')
        return "Follow me on all platforms (or just stalk me, however you'd prefer)";
      else if(this.type === 'random')
        return "Just some random stuff I included on here or had nowhere else to put";
      else if(this.type === 'service')
        return "Services I can provide. To request a service reach out through the email link above";
      else if(this.type === 'business')
        return "A list of business ventures I have worked on.";
      else
        return "";
    }
  },
  methods: {
    select(type) {
      this.type = type;
    }
  }
}
</script>

<style scoped>
.main{
  text-align: center;
}

.pure-menu{
  display: block;
  max-width: 600px;
}

.pure-menu-list{
  display: flex;
  flex-wrap: wrap;
}

.pure-menu-list .pure-menu-item{
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50%;
}


form {
  display: table;
  width: 100%;
}

i {
  display: table-cell;
  padding-left: 10px;
  width: 1px;
}

input {
  display: table-cell;
  font-size: 20px;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
  height: 40px;
  margin-left: 7px;
}
@media only screen and (max-width: 700px){
  .search {
    width: 70%;
  }
}
</style>
