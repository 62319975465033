<template>
  <div id="app">
    <div id="menu">
      <div id="brand">
        <router-link to="/">
          <img src="../public/images/landiinii-black.png">
        </router-link>
      </div>
      <div id="side">
        <a href="mailto:bailey.landeng@gmail.com">
          <div class="menu-item browse">
            <img src="../public/images/email-icon.png">
          </div>
        </a>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>

export default {
  computed: {
    items() {
      let num = this.$root.$data.cart.length;
      return num + " Items";
    }
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}
body {
  margin-top: 50px;
  background: #FFFFFF;
  font-family: "Merriweather", serif;
}
a {
  text-decoration: none;
}
#menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  grid-template-areas: "none brand side";
  margin-bottom: 50px;
}
#menu a {
  color: #B84901;
}
#brand {
  grid-area: brand;
  display: flex;
  justify-content: center;
}
#brand img {
  height: 100%;
  width: 100%;
}
#side {
  grid-area: side;
  display: flex;
  justify-content: center;
}
#side img {
  width: 50px;
  margin: auto;
}
.menu-item {
  display: flex;
  flex-direction: column;
}
.browse {
  margin-top: 20px;
  margin-right: 40px;
}
.terms a{
  display: block;
  color: #cacaca;
  font-size: 15px;
  line-height: 15px;
  padding: 4px;
}

@media only screen and (max-width: 999px){
  #menu {
    grid-template-columns: 1fr 3fr 1fr;
  }
  #brand img {
    height: 80%;
    width: 80%;
    margin: auto;
  }
  .browse {
    margin-top: 15px;
  }
  #side img {
    width: 30%;
    margin: auto;
  }
}
@media only screen and (max-width: 750px){
  #menu {
    grid-template-columns: 1fr 5fr 1fr;
  }
  .browse {
    margin-top: 20px;
  }
  #side img {
    width: 90%;
    margin: auto;
  }
}
@media only screen and (max-width: 750px){
  #brand img {
    height: 80%;
    width: 80%;
    margin: auto;
  }
  #side img {
    width: 65%;
    margin: auto;
  }
}
@media only screen and (max-width: 625px){
  #menu {
    grid-template-columns: 1fr 6fr 1fr;
  }
  #side img {
    width: 120%;
    margin: auto;
  }
}
@media only screen and (max-width: 500px){
  #menu {
    grid-template-columns: 1fr 7fr 1fr;
  }
  .browse {
    margin-top: 10px;
  }
  #side img {
    width: 30px;
    margin: auto;
  }
}
@media only screen and (max-width: 438px){
  #brand img {
    height: 100%;
    width: 100%;
  }
  #side img {
    width: 35px;
  }
}
</style>
