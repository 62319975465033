let mock = [{
        id: 1,
        title: "Resume",
        url: "https://landiinii.com",
        image: 'resume.png',
        type: ['professional']
    },
    {
        id: 2,
        title: "LinkedIn",
        url: "https://www.linkedin.com/in/landengbailey/",
        image: 'linkedin.png',
        type: ['professional', 'social']
    },
    {
        id: 3,
        title: "GitHub",
        url: "https://github.com/landiinii",
        image: 'github.png',
        type: ['professional']
    },
    {
        id: 4,
        title: "Instagram",
        url: "https://instagram.com/landiinii",
        image: 'instagram.png',
        type: ['social']
    },
    {
        id: 5,
        title: "Facebook",
        url: "https://www.facebook.com/landen.bailey.319",
        image: 'facebook.png',
        type: ['social']
    },
    {
        id: 6,
        title: "Twitter",
        url: "https://twitter.com/landiiniii",
        image: 'twitter.png',
        type: ['social']
    },
    {
        id: 7,
        title: "TikTok",
        url: "https://www.tiktok.com/@landiinii",
        image: 'tiktok.png',
        type: ['social']
    },
    {
        id: 8,
        title: "Photography",
        url: "https://photography.landiinii.com",
        image: 'camera.png',
        type: ['service', 'professional', 'random']
    },
    {
        id: 20,
        title: "Costa Rica Budget",
        url: "https://costarica.landiinii.com",
        image: 'costarica.png',
        type: ['service', 'professional', 'random']
    },
    {
        id: 21,
        title: "Operation Outbreak",
        url: "https://oo.byu.edu",
        image: 'oo.png',
        type: ['service', 'professional', 'business']
    },
    {
        id: 9,
        title: "Mechanic",
        url: "https://mechanic.landiinii.com",
        image: 'mechanic.png',
        type: ['service', 'professional']
    },
    {
        id: 10,
        title: "Weather",
        url: "https://weather.landiinii.com",
        image: 'weather.png',
        type: ['random', 'service', 'professional']
    },
    {
        id: 11,
        title: "XKCD",
        url: "https://xkcd.landiinii.com/",
        image: 'xkcd.png',
        type: ['random', 'professional']
    },
    {
        id: 12,
        title: "Outlook Apparel",
        url: "https://outlook-apparel.com",
        image: 'ola.png',
        type: ['business']
    },
    {
        id: 18,
        title: "Stylify",
        url: "https://youtu.be/t1x04p9HYM0",
        type: ['business'],
        image: 'LandiiniiShirt.png',
    },
    {
        id: 19,
        title: "Slidetech",
        url: "https://youtu.be/cJp9E3nlFrI",
        type: ['business', 'professional'],
        image: 'slidetech.png',
    }
]

export default mock;
