<template>
  <div class="wrapper">
    <div v-if="empty">
      <H1>No results for selected parameters</H1>
    </div>
    <div v-else class="products">
      <div class="product" v-for="product in products" :key="product.id">
        <a :href="product.url">
        <div class="info">
          <h1>{{product.title}}</h1>
        </div>
        <div v-if="imageURL(product.image)" class="image">
          <img :src="imageURL(product.image)">
        </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'linkList',
  props: {
    products: Array
  },
  computed: {
    empty(){
      return (this.products.length === 0);
    }
  },
  methods: {
    imageURL(image){
      if(image)
        return `/images/linkImages/${image}`
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 80px;
}

.product {
  margin: 10px;
  width: 200px;
}

.product img {
  border: 2px solid #333;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product .image {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  width: 200px;
  height: 200px;
}

.info {
  background: #000;
  color: #000;
  padding: 10px 30px;
  height: 60px;
  text-align: center;
}

.info h1 {
  font-size: 100%;
  margin: auto;
  color: white;
}
</style>